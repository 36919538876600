import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import * as Sentry from "@sentry/vue";

import './bootstrap';
import '../../public/css/app.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .component("Link", Link)
            .mixin({
                data() {
                    return {
                        colors: {
                            primary: getComputedStyle(document.documentElement).getPropertyValue('--primary').trim(),
                            secondary: getComputedStyle(document.documentElement).getPropertyValue('--secondary').trim(),
                            lightPrimary: getComputedStyle(document.documentElement).getPropertyValue('--light-primary').trim(),
                            lightSecondary: getComputedStyle(document.documentElement).getPropertyValue('--light-secondary').trim(),
                            mainRoleColor: props.initialPage.props.auth.isTechnician ? getComputedStyle(document.documentElement).getPropertyValue('--secondary').trim() : getComputedStyle(document.documentElement).getPropertyValue('--primary').trim(),
                            secondaryRoleColor: props.initialPage.props.auth.isTechnician ? getComputedStyle(document.documentElement).getPropertyValue('--light-primary').trim() : getComputedStyle(document.documentElement).getPropertyValue('--light-secondary').trim(),
                        }
                    }
                },

                // created() {
                //     if (Math.random() > 0.5 && ! document.body.classList.contains('applied')) {
                //     console.log(Math.random());
                //     //check if body has class
                //         document.documentElement.style.setProperty('--primary', '#ff0000', 'important');
                //         document.documentElement.style.setProperty('--secondary', '#00ff00', 'important');
                //
                //         document.body.style.setProperty('background-color', '#ff0000', 'important');
                //         this.colorsApplied = true;
                //     } else {
                //         document.body.classList.add('applied');
                //     }
                // },

                methods: {
                    asset(path) {
                        var base_path = window._asset || '';
                        return base_path + path;
                    },
                    route(name, params, absolute) {
                        return window.route(name, params, absolute);
                    },
                    async confirmDelete(message) {
                        return Swal.fire({
                            title: message,
                            text: 'Esta ação é irreversível',
                            icon: 'error',
                            showCancelButton: true,
                            confirmButtonColor: '#1F97CC',
                            cancelButtonColor: '#dc3545',
                            confirmButtonText: 'Confirmar',
                            cancelButtonText: 'Cancelar',
                            reverseButtons: true,
                        }).then((result) => {
                            return result.value;
                        })
                    },
                    renderActiveBadge(data) {
                        if (data === 1) {
                            return '<span class="myBadge myBadgeAzul">' + 'Ativo' + '</span>';
                        }
                        return '<span class="myBadge myBadgeDanger">' + 'Inativo' + '</span>';
                    },

                    breakProxyLink(data) {
                        return JSON.parse(JSON.stringify(data));
                    },

                    copyToClipboard(data) {
                        let content = data;

                        const el = document.createElement('textarea');
                        el.value = content;
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);

                        toastSuccess('Copiado para a área de transferência');
                    },

                    arrayChunk(arr, len) {
                        var chunks = [], i = 0, n = arr.length;
                        while (i < n) {
                            chunks.push(arr.slice(i, i += len));
                        }
                        return chunks;
                    },

                    parseDate(date, format = 'DD-MM-YYYY HH:mm') {
                        return momentJS(date).format(format);
                    },

                    treatErrorsOnAxios(error) {
                        if (error.response && error.response.status == 422) {
                            let errors = error.response.data.errors;
                            let toReturn = {};

                            Object.keys(errors).forEach((key) => {
                                toReturn[key] = errors[key][0];
                            });

                            return toReturn;
                        }

                        return {};
                    },

                    findInContacts(contacts, type = '*') {
                        if (type === '*') {
                            return contacts[0]?.value ?? 'N/D';
                        }
                        return contacts.find(contact => contact.type === type)?.value ?? 'N/D'
                    },

                    autoPaging(page, data, pageLength) {
                        const chunks = this.arrayChunk(data, pageLength);
                        return chunks[page];
                    },

                    clearUrlParams() {
                        if (this.$inertia.page.url.includes('?')) {
                            this.$inertia.page.url = this.$inertia.page.url.split('?')[0];
                        }
                    }
                },
            });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

            // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
            replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE,
            replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
            tracingOptions: {
                trackComponents: true,
            },
        });

        app.provide('$sentry', Sentry);


        app.mount(el)
    },

})

import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import flatpickr from "flatpickr";
window.flatpickr = flatpickr;

import moment from "moment";
window.momentJS = moment;

import Cropper from 'cropperjs';
window.Cropper = Cropper;

import Swal from "sweetalert2";
window.Swal = Swal;

let messageErrorDefault = "Não conseguimos processar o pedido, tente novamente mais tarde!";
let timerDefault = 5000;
let messageSuccess = 'Gravado com sucesso!';

window.toastSuccess = (message = messageSuccess, timer = timerDefault) => {
    const success = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer,
        customClass: {
            actions: 'd-none',
        }
    });

    success.fire({
        icon: 'success',
        title: message
    });
};

window.toastError = (message = messageErrorDefault, timer = timerDefault) => {
    const messageError = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer,
        customClass: {
            actions: 'd-none',
        }
    });

    messageError.fire({
        icon: 'error',
        title: message
    });
};

InertiaProgress.init();

//This is just here to help IDE know that this function exists and stop giving annoying warnings
window.route = route;

window.confirmDelete = async (message) => {
    return Swal.fire({
        title: message,
        text: 'Esta ação é irreversível',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#1F97CC',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
    }).then((result) => {
        return result.value;
    })
}
